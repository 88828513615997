import React from 'react'

const SuccessCheck = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="103.08" height="96.786" viewBox="0 0 103.08 96.786">
	  <g id="iconfinder_check-circle_2561353" transform="translate(3.009 3.058)">
	    <path id="Path_16768" data-name="Path 16768" d="M88.667,41.324v4.03A43.333,43.333,0,1,1,62.97,5.747" transform="translate(0 0)" fill="none" stroke="#468e9f" stroke-linecap="round" stroke-linejoin="round" strokeWidth="10"/>
	    <path id="Path_16769" data-name="Path 16769" d="M69.667,3,22,50.667l-13-13" transform="translate(23.333 3.354)" fill="none" stroke="#98dfff" stroke-linecap="round" stroke-linejoin="round" strokeWidth="10"/>
	  </g>
	</svg>
)

export default SuccessCheck